import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getAllEventsForAdmin, 
  getAllUsersForAdmin, 
  deleteEventAPIForAdmin,
  updateEventForAdmin,
  createTemplate
} from '../utils/Api';

// 1) Import your Redux actions:
import { createEventList, getEventList } from '../store/events/actions';

const Admin = () => {
  const dispatch = useDispatch();

  const [eventData, setEventData] = useState([]);
  const [showEvents, setShowEvents] = useState(false);

  const [usersData, setUsersData] = useState([]);
  const [showUsers, setShowUsers] = useState(false);

  const [editingEventId, setEditingEventId] = useState(null);
  const [editingEventData, setEditingEventData] = useState({
    name: "",
    description: "",
    start_date: "",
    end_date: ""
  });

  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [templateType, setTemplateType] = useState("");
  const [templateEventId, setTemplateEventId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateText, setTemplateText] = useState("");
  const [templateResult, setTemplateResult] = useState(null);

  // --------------------
  // ADD-EVENT LOGIC (Redux approach)
  // --------------------
  const [addEventForm, setAddEventForm] = useState(false);
  const [newEventData, setNewEventData] = useState({
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    created_by_user_id:""
  });

  const handleAddEvent = () => {
    setAddEventForm(true);
  };

  const handleEventSubmit = (e) => {
    e.preventDefault();
    // 2) Dispatch createEventList with your form data:
    dispatch(createEventList({ 
      name: newEventData.name,
      description: newEventData.description,
      start_date: newEventData.start_date,
      end_date: newEventData.end_date,
      created_by_user_id:newEventData.created_by_user_id
    }));
    
    // Optionally, re-fetch events so Redux state is up-to-date:
    dispatch(getEventList());

    // Close the modal & reset the form
    setAddEventForm(false);
    setNewEventData({
      name: "",
      description: "",
      start_date: "",
      end_date: "",
      created_by_user_id:""
    });
  };

  // --------------------
  // FETCH LOGIC, TOGGLE LOGIC
  // --------------------
  const handleToggleEvents = async () => {
    if (showEvents) {
      setShowEvents(false);
    } else {
      try {
        const data = await getAllEventsForAdmin();
        setEventData(data);
        setShowEvents(true);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    }
  };

  const handleToggleUsers = async () => {
    if (showUsers) {
      setShowUsers(false);
    } else {
      try {
        const data = await getAllUsersForAdmin();
        setUsersData(data);
        setShowUsers(true);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }
  };

  // --------------------
  // EDIT/DELETE LOGIC
  // --------------------
  const handleEditEvent = (event) => {
    setEditingEventId(event.id);
    setEditingEventData({
      name: event.name,
      description: event.description,
      start_date: event.start_date,
      end_date: event.end_date,
      created_by_user_id:event.created_by_user_id
    });
  };

  const handleSaveEdit = async (eventId) => {
    try {
      const updatedEvent = await updateEventForAdmin(eventId, editingEventData);
      const updatedEvents = eventData.map((evt) =>
        evt.id === eventId ? { ...evt, ...updatedEvent } : evt
      );
      setEventData(updatedEvents);
      setEditingEventId(null);
      setEditingEventData({
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        created_by_user_id:""
      });
    } catch (error) {
      console.error("Error updating event:", error);
      alert("Error updating event");
    }
  };

  const handleCancelEdit = () => {
    setEditingEventId(null);
    setEditingEventData({ 
      name: "", 
      description: "", 
      start_date: "", 
      end_date: "" ,
      created_by_user_id:""
    });
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteEventAPIForAdmin(eventId);
      const filtered = eventData.filter((event) => event.id !== eventId);
      setEventData(filtered);
      alert("Event deleted successfully.");
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("Error deleting event.");
    }
  };

  // --------------------
  // TEMPLATE LOGIC
  // --------------------
  const handleToggleTemplateForm = () => {
    setShowTemplateForm(!showTemplateForm);
  };

  const handleCreateTemplate = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        type: templateType,
        event_id: Number(templateEventId),
        name: templateName,
        additional_details: { text: templateText },
      };
      const result = await createTemplate(payload);
      setTemplateResult(result);
      setTemplateType("");
      setTemplateEventId("");
      setTemplateName("");
      setTemplateText("");
      alert("Template created successfully!");
    } catch (error) {
      console.error('Error creating template:', error);
      alert("Error creating template");
    }
  };

  return (
    <div className="p-6">
      <div className="flex gap-4">
        <button
          onClick={handleToggleEvents}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {showEvents ? 'Hide Events' : 'Get All Events'}
        </button>

        <button
          onClick={handleToggleUsers}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          {showUsers ? 'Hide Users' : 'Show Users'}
        </button>

        <button
          onClick={handleToggleTemplateForm}
          className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          {showTemplateForm ? 'Hide Create Template' : 'Show Create Template'}
        </button>

        {/* ADD NEW EVENT BUTTON */}
        <button
          onClick={handleAddEvent}
          className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Add New Event
        </button>
      </div>

      {/* ============== ADD EVENT FORM ============== */}
      {addEventForm && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
          <div className="bg-white rounded p-6 shadow-md w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Create a New Event</h2>
            <form onSubmit={handleEventSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Event Name</label>
                <input
                  type="text"
                  required
                  value={newEventData.name}
                  onChange={(e) => setNewEventData({ ...newEventData, name: e.target.value })}
                  className="mt-1 block w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  rows="3"
                  required
                  value={newEventData.description}
                  onChange={(e) => setNewEventData({ ...newEventData, description: e.target.value })}
                  className="mt-1 block w-full p-2 border rounded"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Start Date</label>
                <input
                  type="date"
                  required
                  value={newEventData.start_date}
                  onChange={(e) => setNewEventData({ ...newEventData, start_date: e.target.value })}
                  className="mt-1 block w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">End Date</label>
                <input
                  type="date"
                  required
                  value={newEventData.end_date}
                  onChange={(e) => setNewEventData({ ...newEventData, end_date: e.target.value })}
                  className="mt-1 block w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>User ID</label>
                <input
                type = "text"
                required
                value = {newEventData.created_by_user_id}
                onChange={(e)=>setNewEventData({...newEventData,created_by_user_id:e.target.value})}
                className='mt-1 block w-full p-2 border rounded'
                />

              </div>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setAddEventForm(false)}
                  className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Create Event
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* ============== RENDER EVENTS LIST ============== */}
      {showEvents && (
        <div className="mt-6">
          {eventData.length > 0 ? (
            <ul className="space-y-4">
              {eventData.map((event) => (
                <li key={event.id} className="p-4 border rounded shadow">
                  {editingEventId === event.id ? (
                    // Edit form for the event
                    <div className="space-y-2">
                      <input
                        type="text"
                        value={editingEventData.name}
                        onChange={(e) =>
                          setEditingEventData({ ...editingEventData, name: e.target.value })
                        }
                        className="p-2 border rounded w-full"
                        placeholder="Event Name"
                      />
                      <textarea
                        value={editingEventData.description}
                        onChange={(e) =>
                          setEditingEventData({ ...editingEventData, description: e.target.value })
                        }
                        className="p-2 border rounded w-full"
                        placeholder="Event Description"
                      />
                      <div className="flex gap-2">
                        <input
                          type="date"
                          value={editingEventData.start_date}
                          onChange={(e) =>
                            setEditingEventData({ ...editingEventData, start_date: e.target.value })
                          }
                          className="p-2 border rounded"
                        />
                        <input
                          type="date"
                          value={editingEventData.end_date}
                          onChange={(e) =>
                            setEditingEventData({ ...editingEventData, end_date: e.target.value })
                          }
                          className="p-2 border rounded"
                        />
                      </div>
                      <div className="flex gap-2 mt-2">
                        <button
                          onClick={() => handleSaveEdit(event.id)}
                          className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-700"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    // Read-only event details
                    <div>
                      <h2 className="text-xl font-bold">{event.name}</h2>
                      <p>{event.description}</p>
                      <p className="text-sm text-gray-600">
                        {event.start_date} - {event.end_date}
                      </p>
                      <p className="text-sm text-gray-600">ID: {event.id}</p>
                      <div className="mt-2 flex gap-2">
                        <button
                          onClick={() => handleEditEvent(event)}
                          className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                        >
                          Edit Event
                        </button>
                        <button
                          onClick={() => handleDeleteEvent(event.id)}
                          className="px-2 py-1 bg-red-600 text-white rounded hover:bg-red-700"
                        >
                          Delete Event
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No events found.</p>
          )}
        </div>
      )}

      {/* ============== RENDER USERS LIST ============== */}
      {showUsers && (
        <div className="mt-6">
          {usersData.length > 0 ? (
            <ul className="space-y-4">
              {usersData.map((user) => (
                <li key={user.id} className="p-4 border rounded shadow">
                  <h2 className="text-xl font-bold">{user.name}</h2>
                  <p className="text-sm text-gray-600">{user.email}</p>
                  <p className="text-sm text-gray-600">{user.phone_number}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No users found.</p>
          )}
        </div>
      )}

      {/* ============== RENDER CREATE TEMPLATE FORM ============== */}
      {showTemplateForm && (
        <div className="mt-6 p-4 border rounded shadow">
          <h2 className="text-xl font-bold mb-4">Create Template</h2>
          <form onSubmit={handleCreateTemplate} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <input
                type="text"
                value={templateType}
                onChange={(e) => setTemplateType(e.target.value)}
                className="mt-1 block w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Event ID</label>
              <input
                type="number"
                value={templateEventId}
                onChange={(e) => setTemplateEventId(e.target.value)}
                placeholder="e.g. 20"
                className="mt-1 block w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Template Name</label>
              <input
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                className="mt-1 block w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Additional Details</label>
              <input
                type="text"
                value={templateText}
                onChange={(e) => setTemplateText(e.target.value)}
                className="mt-1 block w-full p-2 border rounded"
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              Create Template
            </button>
          </form>
          {templateResult && (
            <div className="mt-4 p-4 border rounded">
              <pre>{JSON.stringify(templateResult, null, 2)}</pre>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Admin;
