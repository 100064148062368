import React, { useState } from 'react';
import axios from 'axios'; // Make sure to install axios: `npm install axios`
import { Button } from "../components/ui/Button";
import MessageStatus from './MessageStatus';
import config from '../config';

export default function BroadcastMessage({ templateData, guestList, onBack, placeholders, messageLines }) {
  const [showMessageStatus, setShowMessageStatus] = useState(false);
  const [loading, setLoading] = useState(false); // To handle loading state
  const [error, setError] = useState(null); // To handle API errors

  const handleSendNow = async () => {
    setLoading(true);
    setError(null);

    try {
      // API call to the Node backend
      const response = await axios.post(`${config.BACKEND_URL}/send-message`, {
        campaignName: 'send rsvp messages',
        source: templateData?.name,
        media: {
          url: placeholders?.mediaUrl,
          filename: 'sample_media'
        },
        templates: Object.keys(placeholders).filter(key => key !== "mediaUrl").map(key => placeholders[key]).slice(0, 3),
        paramsFallbackValue: { FirstName: 'User' },
        guestList: guestList.data,
      });

      console.log('Message sent successfully:', response.data);

      // Show message status on success
      setShowMessageStatus(true);
    } catch (err) {
      console.error('Error sending messages:', err.response || err);
      setError('Failed to send messages. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (showMessageStatus) {
    return (
      <MessageStatus
        templateData={templateData}
        messageLines={messageLines}
        guestList={guestList}
        onBack={() => { setShowMessageStatus(false); }}
        placeholders={placeholders}
      />
    );
  }

  return (
    <div className="p-6 max-w-2xl mx-auto bg-white rounded-xl shadow-md">
      <h1 className="text-2xl font-bold mb-4">Event Update</h1>
      <div className="mt-4 p-4 bg-gray-100 rounded-lg">
        {messageLines.map((line, index) => (
          <p key={index} className={index === 0 ? '' : 'mt-2'} dangerouslySetInnerHTML={{ __html: line }} />
        ))}
      </div>
      <p className="mb-4 text-lg">Sending message to {guestList?.data?.length || 0} people.</p>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack} disabled={loading}>
          Back
        </Button>
        <Button variant="outline" disabled={loading}>
          Save as Draft
        </Button>
        <Button onClick={handleSendNow} disabled={loading}>
          {loading ? 'Sending...' : 'Send Now'}
        </Button>
      </div>
    </div>
  );
}
