import { takeLatest, call, put } from 'redux-saga/effects';
import { uploadTeamListSuccess, uploadTeamListFailure, getTeamListSuccess, getTeamListFailure, createTeamListSuccess, createTeamListFailure } from './actions';
import { CREATE_TEAMS_REQUEST, GET_TEAMS_REQUEST, UPLOAD_TEAMS_REQUEST } from './actionTypes';
import { getTeam, uploadteams, createTeam } from '../../utils/Api';


export function* handleTeamsUpload(action) {
  const { eventId, teamList } = action.payload;

  try {
    const data = yield call(uploadteams, eventId, teamList);
    yield put(uploadTeamListSuccess(data));
  } catch (error) {
    yield put(uploadTeamListFailure(error.message));
  }
}

export function* getTeamMembers(action) {
  const { eventId } = action.payload;

  try {
    const data = yield call(getTeam, eventId);
    yield put(getTeamListSuccess(data));
  } catch (error) {
    yield put(getTeamListFailure(error.message));
  }
}

export function* createTeamMembers(action) {
  const { eventId, teamList } = action.payload;
  try {
    const data = yield call(createTeam, eventId, teamList);
    yield put(createTeamListSuccess(data));
  } catch (error) {
    yield put(createTeamListFailure(error.message));
  }
}

export function* teamsSaga() {
  yield takeLatest(UPLOAD_TEAMS_REQUEST, handleTeamsUpload);
  yield takeLatest(GET_TEAMS_REQUEST, getTeamMembers)
  yield takeLatest(CREATE_TEAMS_REQUEST, createTeamMembers)
}
