import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from '../store/auth/login/actions';
import CountryCodeDropdown from "./CountryCodeDropdown";
import PinInput from "./PinInput";
import Input from "../components/Input";
import Button from "../components/Button";
import { Smartphone, KeyRound } from 'lucide-react';

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  const [pin, setPin] = useState(['', '', '', '']);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const [selectedDialCode, setSelectedDialCode] = useState('+91');

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/homepage');
    }
  }, [navigate]);
  
  const PhoneNumberWithCode = selectedDialCode + phoneOrEmail;
  const normalizedPhone = PhoneNumberWithCode.replace(/^\+/, '');    // remove leading "+"


  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!normalizedPhone) return;
    setLoading(true);
    try {
      await dispatch(loginRequest(normalizedPhone, pin.join("")));
      navigate('/homepage');
    } catch (err) {
      setError(err.message || "Login failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md">
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-lg">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
        )}

        <form onSubmit={handleLoginSubmit} className="space-y-6">
          <div className="flex items-center justify-center mb-8">
            <div className="bg-blue-100 p-4 rounded-full">
              <Smartphone className="w-8 h-8 text-blue-600" />
            </div>
          </div>

          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-800">Welcome Back!</h2>
            <p className="text-gray-600 mt-2">Log in to your account</p>
          </div>

          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <CountryCodeDropdown
                selectedCode={selectedDialCode}
                onChange={setSelectedDialCode}
                className="bg-white border border-gray-200 rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <Input
                type="text"
                placeholder="Enter Phone Number"
                value={phoneOrEmail}
                onChange={(e) => setPhoneOrEmail(e.target.value)}
                className="flex-1 bg-white border border-gray-200 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div className="space-y-2 justify-center">
              {/* <div className="flex items-center space-x-2 mb-2">
                <KeyRound className="w-4 h-4 text-gray-500" />
                <label className="block text-sm font-medium text-gray-700">Enter PIN</label>
              </div> */}
              <PinInput
                value={pin}
                onChange={setPin}
                label="PIN"
                className="flex justify-center gap-2"
              />
            </div>
          </div>

          <Button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
          >
            {loading ? 'Logging in...' : 'Log In'}
          </Button>

          <div className="mt-6 text-center">
            <button
              type="button"
              onClick={() => navigate('/forgot-password')}
              className="text-blue-600 hover:text-blue-700 text-sm font-medium transition-colors duration-200"
            >
              Forgot PIN?
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;