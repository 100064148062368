import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BroadcastMessage from './BroadcastMessage'; // Import BroadcastMessage component
import { getGuestList } from '../store/guests/actions';
import { useParams } from 'react-router-dom';

export default function AudienceSelection({ placeholders, onBack, templateData }) {
  const { guestList } = useSelector((state) => ({
    guestList:state.guestsReducer.guestList
  }))
  const [showBroadcastMessage, setShowBroadcastMessage] = useState(false);
  const [filters , setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const dispatch = useDispatch();
  const { eventId } = useParams();


  useEffect(()=>{
    dispatch(getGuestList(eventId, [selectedFilters]));
  },[selectedFilters])

  useEffect(() => {
    if (guestList?.data?.length && filters.length === 0) {
      const uniqueCategories = [...new Set(guestList.data.map((item) => item.category))];
      setFilters(uniqueCategories);
    }
  }, [guestList]);

  const handleNext = () => {
    setShowBroadcastMessage(true); 
  };

  const replacePlaceholders = (template, placeholders) => {
    const placeholderRegex = /\*(\w+)\*/g;
    return template.replace(placeholderRegex, (_, key) => {
      const value = placeholders[key] || `<${key}>`;
      return `<strong>${value}</strong>`;
    });
  };

  const handleFilterChange = (category) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(category)
        ? prevFilters.filter((filter) => filter !== category)
        : [...prevFilters, category]
    );
  };

  const dynamicMessage = replacePlaceholders(
    templateData?.additional_details?.text ,
    placeholders
  );

  const messageLines = dynamicMessage.split('\n');

  return (
    <div className="p-6">
      {!showBroadcastMessage ? (
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-x-6">
          <div className="bg-white border rounded-lg shadow-md p-4 w-full md:w-1/2">
            <h3 className="text-lg font-semibold">Event Update</h3>
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
              {messageLines.map((line, index) => (
                <p key={index} className={index === 0 ? '' : 'mt-2'} dangerouslySetInnerHTML={{ __html: line }} />
              ))}
            </div>
          </div>

          <div className="bg-white border rounded-lg shadow-md p-4 w-full md:w-1/2">
            <h3 className="text-lg font-semibold">Who would you like to send the Broadcast to?</h3>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Audience <span className="text-red-500">*</span></label>
              <select className="mt-1 block w-full p-2 border rounded-md">
                <option>Source: Guest list: Category</option>
              </select>
            </div>

            <div className="mt-4">
              <h4 className="text-sm font-semibold">Filters:</h4>
              {filters.length > 0 ? (
                <div className="mt-2 space-y-2">
                  {filters.map((category) => (
                    <div key={category}>
                      <input
                        type="checkbox"
                        id={category}
                        name={category}
                        checked={selectedFilters.includes(category)}
                        onChange={() => handleFilterChange(category)}
                      />
                      <label htmlFor={category} className="ml-2 capitalize">
                        {category}
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm text-gray-500">No categories available</p>
              )}
              
            </div>

            <div className="mt-4 flex space-x-4">
              <button className="bg-gray-300 text-white px-4 py-2 rounded-md hover:bg-gray-900" onClick={onBack}>Back</button>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Save as Draft</button>
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-900"
                onClick={handleNext} 
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <BroadcastMessage 
        templateData={templateData}
        messageLines={messageLines}
        onBack={()=>setShowBroadcastMessage(false)}
        guestList={guestList}
        placeholders={placeholders}
         />
      )}
    </div>
  );
}
