import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthForm from './pages/AuthForm';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import WorkSpace from './components/WorkSpace';
import AuthMiddleware from './components/AuthMiddleware';
import ForgotPasswordForm from './pages/ForgotPassword';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import Admin from './components/Admin';
import AdminProtected from './components/AdminProtected';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <AuthForm />,
  },
  {
    path: "/signup",
    element: <AuthForm />,
  },
  {
    path:"/privacypolicy",
    element:<PrivacyPolicy/>,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordForm />,
  },
  {
    path:"/admin",
    element:(
    <AdminProtected>
      <Admin/>
      </AdminProtected>
    ),
  },
  {
    element: <AuthMiddleware />, // Protects the following routes
    children: [
      {
        path: "/homepage",
        element: <HomePage />,
      },
      {
        path: "/:eventId/:eventName/*",
        element: <WorkSpace />,
        children: [
          { path: "bulletins", element: <WorkSpace /> },
          { path: "guests", element: <WorkSpace /> },
          { path: "newchecklist", element: <WorkSpace /> },
          { path: "communications", element: <WorkSpace /> },
        ],
      },
      {
        path: "/check_list",
        element: <HomePage />,
      },
      {
        path: "/guest_manager",
        element: <HomePage />,
      },
    ],
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
