import React,{useState} from 'react';
import { useParams } from 'react-router-dom';
import { updateDriveLink } from '../utils/Api';
import { Copy, Check, FolderPlus, Share2, Link } from 'lucide-react';

function App() {
  const [copied, setCopied] = React.useState(false);

  const [documentsLink, setDocumentsLink] = useState('');
  const [picturesLink, setPicturesLink] = useState('');
  const [travelTickets, setTravelTickets] = useState('');

  const { eventId } = useParams();

  const emailAddress = "eventsa-shared-library@massive-seer-449504-r2.iam.gserviceaccount.com";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(emailAddress);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleDocumentsSubmit = async () => {
    setDocumentsLink('')
    if (!documentsLink) {
      alert('Please enter the Documents folder link!');
      return;
    }
    try {
      const result = await updateDriveLink({
        eventId,
        driveUrl: documentsLink,
        pages: 'id_page', 
      });
      console.log('Documents link updated:', result);
      alert('Documents link updated successfully!');
    } catch (error) {
      console.error('Error updating documents link:', error);
      alert(`Error: ${error.message}`);
    }
  };

  const handlePicturesSubmit = async () => {
    setPicturesLink('');
    if (!picturesLink) {
      alert('Please enter the Pictures folder link!');
      return;
    }
    try {
      const result = await updateDriveLink({
        eventId,
        driveUrl: picturesLink,
        pages: 'photos_page',
      });
      console.log('Pictures link updated:', result);
      alert('Pictures link updated successfully!');
    } catch (error) {
      console.error('Error updating pictures link:', error);
      alert(`Error: ${error.message}`);
    }
  };
  const handleticketsSubmit =  async()=>{
    setTravelTickets('');
    if(!travelTickets){
    alert('Please enter the Tickets folder link');
    return;
    }
    try{
      const result = await updateDriveLink({
        eventId,
        driveUrl: travelTickets,
        pages:'ticket_page',
      });
      alert('Pictures updated successfully');
    }
    catch(error){
      console.error('Error updating the tickets link',error);
      alert(`Error: ${error.message}`)
    }
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto pt-12 px-4 sm:px-6 lg:px-8 pb-12">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-gradient-to-r from-blue-600 to-blue-700 px-6 py-4">
            <h2 className="text-2xl font-bold text-white">
              Simple steps to get the guest media in your Google Drive
            </h2>
          </div>

          <div className="p-6">
            <div className="space-y-8">
              {/* Step 1 */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="bg-blue-100 rounded-full p-2">
                    <FolderPlus className="w-6 h-6 text-blue-600" />
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-medium text-gray-900">Step 1</h2>
                  <p className="mt-1 text-gray-600">Create a new folder in your Google Drive.</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="bg-blue-100 rounded-full p-2">
                    <Share2 className="w-6 h-6 text-blue-600" />
                  </div>
                </div>
                <div className="flex-grow">
                  <h2 className="text-lg font-medium text-gray-900">Step 2</h2>
                  <p className="mt-1 text-gray-600">
                    Share the folder with the following email and grant editor access:
                  </p>
                  <div className="mt-2 flex items-center space-x-2">
                    <code className="px-4 py-2 bg-gray-100 rounded text-sm flex-grow break-all">
                      {emailAddress}
                    </code>
                    <button
                      onClick={handleCopy}
                      className={`inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md
                        ${
                          copied
                            ? 'bg-green-100 text-green-800 hover:bg-green-200'
                            : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
                        } transition-colors duration-200`}
                    >
                      {copied ? (
                        <>
                          <Check className="w-4 h-4 mr-1" />
                          Copied!
                        </>
                      ) : (
                        <>
                          <Copy className="w-4 h-4 mr-1" />
                          Copy
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="bg-blue-100 rounded-full p-2">
                    <Link className="w-6 h-6 text-blue-600" />
                  </div>
                </div>
                <div className="flex-grow">
                  <h2 className="text-lg font-medium text-gray-900">Step 3</h2>
                  <p className="mt-1 text-gray-600">
                    At the same place from where you gave access in your Drive, there would be an
                    option to copy link. Copy the link for both the Documents folder and the Pictures
                    folder, then paste them in their respective fields below.
                  </p>

                  <div className="mt-4 space-y-6">
                    <div>
                      <label
                        htmlFor="documents"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Documents:
                      </label>
                      <div className="mt-1 flex space-x-2">
                        <input
                          id="documents"
                          type="url"
                          value={documentsLink}
                          onChange={(e) => setDocumentsLink(e.target.value)}
                          placeholder="Paste your Documents folder link here"
                          className="flex-1 px-4 py-2 border border-gray-300 rounded-md 
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                            transition-colors duration-200"
                        />
                        <button
                          type="button"
                          onClick={handleDocumentsSubmit}
                          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded 
                            hover:bg-blue-700 transition-colors duration-200 whitespace-nowrap"
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="ticket"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tickets:
                      </label>
                      <div className="mt-1 flex space-x-2">
                        <input
                          id="ticket"
                          type="url"
                          value={travelTickets}
                          onChange={(e) => setTravelTickets(e.target.value)}
                          placeholder="Paste your tickets folder link here"
                          className="flex-1 px-4 py-2 border border-gray-300 rounded-md 
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                            transition-colors duration-200"
                        />
                        <button
                          type="button"
                          onClick={handleticketsSubmit}
                          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded 
                            hover:bg-blue-700 transition-colors duration-200 whitespace-nowrap"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="pictures"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Pictures:
                      </label>
                      <div className="mt-1 flex space-x-2">
                        <input
                          id="pictures"
                          type="url"
                          value={picturesLink}
                          onChange={(e) => setPicturesLink(e.target.value)}
                          placeholder="Paste your Pictures folder link here"
                          className="flex-1 px-4 py-2 border border-gray-300 rounded-md 
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                            transition-colors duration-200"
                        />
                        <button
                          type="button"
                          onClick={handlePicturesSubmit}
                          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded 
                            hover:bg-blue-700 transition-colors duration-200 whitespace-nowrap"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of Step 3 */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;