import React from 'react';
import { Button } from "./ui/Button"; // Importing your Button component

// Reusable Table Component
const ReusableTable = ({ columns, data, onActionClick, actionText = 'Select' }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={`py-2 px-4 text-left ${column.className || ''}`}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} className="py-2 px-4">
                  {row[column.field] || '--'} {/* Fallback if data is missing */}
                </td>
              ))}
              {onActionClick && (
                <td className="py-2 px-4">
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => onActionClick(row)}
                  >
                    {actionText}
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReusableTable;
