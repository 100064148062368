import { takeLatest, call, put } from 'redux-saga/effects';
import { uploadGuestListSuccess, uploadGuestListFailure, getGuestListSuccess, getGuestListFailure } from './actions';
import { GET_GUESTS_REQUEST, UPLOAD_GUESTS_REQUEST } from './actionTypes';
import { uploadGuests, getGuestsList } from '../../utils/Api';


export function* handleGuestUpload(action) {
  const { eventId, guestList } = action.payload;

  try {
    const data = yield call(uploadGuests, eventId, guestList);
    yield put(uploadGuestListSuccess(data));
  } catch (error) {
    yield put(uploadGuestListFailure(error.message));
  }
}

export function* getEventGuestList(action) {
  const { eventId, filters } = action.payload;

  try {
    const data = yield call(getGuestsList, eventId, filters);
    yield put(getGuestListSuccess(data));
  } catch (error) {
    yield put(getGuestListFailure(error.message));
  }
}

export function* guestSaga() {
  yield takeLatest(UPLOAD_GUESTS_REQUEST, handleGuestUpload);
  yield takeLatest(GET_GUESTS_REQUEST, getEventGuestList);
}
