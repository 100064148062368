import {
  UPLOAD_TEMPLATES_FAILURE, UPLOAD_TEMPLATES_SUCCESS, UPLOAD_TEMPLATES_REQUEST, GET_TEMPLATES_FAILURE, GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_REQUEST, CREATE_TEMPLATES_FAILURE, CREATE_TEMPLATES_SUCCESS, CREATE_TEMPLATES_REQUEST
} from './actionTypes';

export const createTemplateList = (eventId, TemplateList) => ({
  type: CREATE_TEMPLATES_REQUEST,
  payload: { eventId, TemplateList },
});

export const createTemplateListSuccess = (userData) => ({
  type: CREATE_TEMPLATES_SUCCESS,
  payload: userData,
});

export const createTemplateListFailure = (error) => ({
  type: CREATE_TEMPLATES_FAILURE,
  payload: error,
});

export const getTemplateList = (eventId, TemplateList) => ({
  type: GET_TEMPLATES_REQUEST,
  payload: { eventId, TemplateList },
});

export const getTemplateListSuccess = (userData) => ({
  type: GET_TEMPLATES_SUCCESS,
  payload: userData,
});

export const getTemplateListFailure = (error) => ({
  type: GET_TEMPLATES_FAILURE,
  payload: error,
});
