import { takeLatest, call, put } from 'redux-saga/effects';
import { signup } from '../../../utils/Api';
import { SIGNUP_REQUEST } from './actionTypes';
import { signupFailure, signupSuccess } from './actions';


export function* handleSignup(action) {
  const { userName, email, password } = action.payload;

  try {
    const userData = yield call(signup, userName, email, password);
    yield put(signupSuccess(userData));

    // navigate('/login_in')
  } catch (error) {
    yield put(signupFailure(error.message));
  }
}

export function* signupSaga() {
  yield takeLatest(SIGNUP_REQUEST, handleSignup);
}
