import countryCodes from '../helpers/countryCodes';

const CountryCodeDropdown = ({ selectedCode, onChange }) => {

  const codes = countryCodes[0];

  return (
    <select
      value={selectedCode}
      onChange={(e) => onChange(e.target.value)}
      className="border border-gray-300 p-2 rounded w-20"
    >
      {codes.map((country) => (
        <option key={country.code} value={country.dial_code}>
          {country.emoji} {country.name} ({country.dial_code})
        </option>
      ))}
    </select>
  );
};

export default CountryCodeDropdown;
