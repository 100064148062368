import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PinInput from './PinInput';
import Input from '../components/Input';
import Button from '../components/Button';
import { forgotPassword } from '../utils/Api';
import CountryCodeDropdown from './CountryCodeDropdown';
import { Smartphone, KeyRound, ShieldCheck } from 'lucide-react';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const [newPassword, setNewPassword] = useState(['', '', '', '']); 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/homepage');
    }
  }, [navigate]);

  const PhoneNumberWithCode = selectedCountryCode + phone;
  const normalizedPhone = PhoneNumberWithCode.replace(/^\+/, '');    // remove leading "+"


  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await forgotPassword({
        step: 'phoneCheck',
        phone_number: normalizedPhone
      });
      setStep(2);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const otpString = otp.join('');
      await forgotPassword({
        step: 'verifyOtp',
        phone_number: normalizedPhone,
        otp: otpString
      });
      setStep(3);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const passString = newPassword.join('');
      await forgotPassword({
        step: 'resetPassword',
        phone_number: normalizedPhone,
        new_password: passString
      });
      navigate('/login');
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => (
    <form onSubmit={handlePhoneCheck} className="space-y-6">
      <div className="flex items-center justify-center mb-8">
        <div className="bg-blue-100 p-4 rounded-full">
          <Smartphone className="w-8 h-8 text-blue-600" />
        </div>
      </div>

      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Reset Your PIN</h2>
        <p className="text-gray-600 mt-2">Enter your phone number to continue</p>
      </div>

      <div className="flex items-center space-x-2">
        <CountryCodeDropdown
          selectedCode={selectedCountryCode}
          onChange={setSelectedCountryCode}
          className="bg-white border border-gray-200 rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <Input
          type="tel"
          placeholder="Enter phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="flex-1 bg-white border border-gray-200 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <Button
        type="submit"
        disabled={loading}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
      >
        {loading ? 'Checking...' : 'Continue'}
      </Button>
    </form>
  );

  const renderStep2 = () => (
    <form onSubmit={handleOtpSubmit} className="space-y-6">
      <div className="flex items-center justify-center mb-8">
        <div className="bg-blue-100 p-4 rounded-full">
          <ShieldCheck className="w-8 h-8 text-blue-600" />
        </div>
      </div>

      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Verify OTP</h2>
        <p className="text-gray-600 mt-2">Enter the OTP sent to your WhatsApp</p>
      </div>

      <div className="space-y-2">
        <PinInput
          value={otp}
          onChange={setOtp}
          label="OTP"
          className="flex justify-center gap-2"
        />
      </div>

      <Button
        type="submit"
        disabled={loading}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
      >
        {loading ? 'Verifying...' : 'Verify OTP'}
      </Button>
    </form>
  );

  const renderStep3 = () => (
    <form onSubmit={handleResetPassword} className="space-y-6">
      <div className="flex items-center justify-center mb-8">
        <div className="bg-blue-100 p-4 rounded-full">
          <KeyRound className="w-8 h-8 text-blue-600" />
        </div>
      </div>

      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Create New PIN</h2>
        <p className="text-gray-600 mt-2">Enter your new PIN to secure your account</p>
      </div>

      <div className="space-y-2">
        <PinInput
          value={newPassword}
          onChange={setNewPassword}
          label="New PIN"
          className="flex justify-center gap-2"
        />
      </div>

      <Button
        type="submit"
        disabled={loading}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
      >
        {loading ? 'Resetting...' : 'Reset PIN'}
      </Button>
    </form>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md">
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-lg">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
        )}

        {step === 1 && renderStep1()}
        {step === 2 && renderStep2()}
        {step === 3 && renderStep3()}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;