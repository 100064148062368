// store/auth/actionTypes.js
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';
export const CREATE_EVENTS_REQUEST = 'CREATE_EVENTS_REQUEST';
export const CREATE_EVENTS_SUCCESS = 'CREATE_EVENTS_SUCCESS';
export const CREATE_EVENTS_FAILURE = 'CREATE_EVENTS_FAILURE';
export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';
