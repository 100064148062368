import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PinInput from './PinInput';
import Input from '../components/Input';
import Button from '../components/Button';
import { signup } from '../utils/Api';
import CountryCodeDropdown from './CountryCodeDropdown';
import { Smartphone, Lock, User } from 'lucide-react';

const SignupForm = () => {
  const navigate = useNavigate();
  const [signupStep, setSignupStep] = useState(1);
  const [signupPhone, setSignupPhone] = useState('');
  const [signupOtp, setSignupOtp] = useState(['', '', '', '']);
  const [signupName, setSignupName] = useState('');
  const [signupPin, setSignupPin] = useState(['', '', '', '']);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/homepage');
    }
  }, [navigate]);

  const PhoneNumberWithCode = selectedCountryCode + signupPhone;
  const normalizedPhone = PhoneNumberWithCode.replace(/^\+/, '');    // remove leading "+"


  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await signup({
        step: 'phoneCheck',
        phone_number: normalizedPhone,
      });
      if (response.exists && response.hasPassword) {
        navigate('/login');
      } else {
        setSignupStep(2);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const otpString = signupOtp.join('');
      await signup({
        step: 'verifyOtp',
        phone_number: normalizedPhone,
        otp: otpString,
      });
      setSignupStep(3);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const pinString = signupPin.join('');
      const response = await signup({
        step: 'createPassword',
        phone_number: normalizedPhone,
        name: signupName,
        password: pinString,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      navigate('/');
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderSignupStep1 = () => {
    return (
      <form onSubmit={handlePhoneCheck} className="space-y-6">
        <div className="flex items-center justify-center mb-8">
          <div className="bg-blue-100 p-4 rounded-full">
            <Smartphone className="w-8 h-8 text-blue-600" />
          </div>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800">Welcome!</h2>
          <p className="text-gray-600 mt-2">Enter your phone number to get started</p>
        </div>
        <div className="flex items-center space-x-2">
          <CountryCodeDropdown
            selectedCode={selectedCountryCode}
            onChange={setSelectedCountryCode}
            className="bg-white border border-gray-200 rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <Input
            type="tel"
            placeholder="Enter phone number"
            value={signupPhone}
            onChange={(e) => setSignupPhone(e.target.value)}
            className="flex-1 bg-white border border-gray-200 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <Button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
        >
          {loading ? 'Checking...' : 'Continue'}
        </Button>
      </form>
    );
  };

  const renderSignupStep2 = () => {
    return (
      <form onSubmit={handleOtpSubmit} className="space-y-6">
        <div className="flex items-center justify-center mb-8">
          <div className="bg-blue-100 p-4 rounded-full">
            <Lock className="w-8 h-8 text-blue-600" />
          </div>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800">Verify Your Number</h2>
          <p className="text-gray-600 mt-2">Enter the OTP sent to your WhatsApp</p>
        </div>
        <PinInput
          value={signupOtp}
          onChange={setSignupOtp}
          label="OTP"
          className="flex justify-center gap-2"
        />
        <Button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
        >
          {loading ? 'Verifying...' : 'Verify OTP'}
        </Button>
      </form>
    );
  };

  const renderSignupStep3 = () => {
    return (
      <form onSubmit={handleCreatePassword} className="space-y-6">
        <div className="flex items-center justify-center mb-8">
          <div className="bg-blue-100 p-4 rounded-full">
            <User className="w-8 h-8 text-blue-600" />
          </div>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800">Complete Your Profile</h2>
          <p className="text-gray-600 mt-2">Set up your account details</p>
        </div>
        <Input
          type="text"
          placeholder="Enter your full name"
          value={signupName}
          onChange={(e) => setSignupName(e.target.value)}
          className="w-full bg-white border border-gray-200 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Create PIN</label>
          <PinInput
            value={signupPin}
            onChange={setSignupPin}
            label="PIN"
            className="flex justify-center gap-2"
          />
        </div>
        <Button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg transition-colors duration-200"
        >
          {loading ? 'Creating Account...' : 'Complete Setup'}
        </Button>
      </form>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md">
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-lg">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
        )}
        {signupStep === 1 && renderSignupStep1()}
        {signupStep === 2 && renderSignupStep2()}
        {signupStep === 3 && renderSignupStep3()}
      </div>
    </div>
  );
};

export default SignupForm;