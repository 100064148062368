import {
  UPLOAD_TEAMS_FAILURE, UPLOAD_TEAMS_SUCCESS, UPLOAD_TEAMS_REQUEST, GET_TEAMS_FAILURE, GET_TEAMS_SUCCESS,
  GET_TEAMS_REQUEST, CREATE_TEAMS_FAILURE, CREATE_TEAMS_SUCCESS, CREATE_TEAMS_REQUEST
} from './actionTypes';

export const uploadTeamList = (eventId, teamList) => ({
  type: UPLOAD_TEAMS_REQUEST,
  payload: { eventId, teamList },
});

export const uploadTeamListSuccess = (userData) => ({
  type: UPLOAD_TEAMS_SUCCESS,
  payload: userData,
});

export const uploadTeamListFailure = (error) => ({
  type: UPLOAD_TEAMS_FAILURE,
  payload: error,
});

export const createTeamList = (eventId, teamList) => ({
  type: CREATE_TEAMS_REQUEST,
  payload: { eventId, teamList },
});

export const createTeamListSuccess = (userData) => ({
  type: CREATE_TEAMS_SUCCESS,
  payload: userData,
});

export const createTeamListFailure = (error) => ({
  type: CREATE_TEAMS_FAILURE,
  payload: error,
});

export const getTeamList = (eventId, teamList) => ({
  type: GET_TEAMS_REQUEST,
  payload: { eventId, teamList },
});

export const getTeamListSuccess = (userData) => ({
  type: GET_TEAMS_SUCCESS,
  payload: userData,
});

export const getTeamListFailure = (error) => ({
  type: GET_TEAMS_FAILURE,
  payload: error,
});
