import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchMessageHistory } from '../utils/Api';
import * as XLSX from 'xlsx';

const MessageHistory = () => {
  const { eventId } = useParams();

  const [history, setHistory] = useState([]); // raw data from API
  const [grouped, setGrouped] = useState([]); // grouped data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getHistory = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await fetchMessageHistory(eventId); // raw array of items
        setHistory(data);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      } finally {
        setLoading(false);
      }
    };

    getHistory();
  }, [eventId]);

  // Group once "history" is fetched
  useEffect(() => {
    const groupedData = {};

    history.forEach((item) => {
      const dateKey = item.createdAt; // Use the createdAt directly from the API response
      const tpl = item.template; // e.g., "[\"{{first_name}}\"]" or "\"lunchh_e1\""
      const groupKey = `${dateKey}__${tpl}`;

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = {
          createdAt: dateKey,
          template: tpl,
          messageIds: [],
        };
      }
      groupedData[groupKey].messageIds.push(item.messageid);
    });

    // Convert grouped object to an array
    setGrouped(Object.values(groupedData));
  }, [history]);

  // Format timestamp into a user-readable format
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust format as needed
  };

  // Function to download Excel for a particular group
  const handleDownloadExcel = (group) => {
    const rows = group.messageIds.map((msgId) => ({
      Timestamp: group.createdAt,
      Template: group.template,
      MessageId: msgId,
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Message IDs');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `messageIDs_${group.createdAt}_${group.template}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
    

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      {!loading && !error && (
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr style={{ backgroundColor: '#f4f4f4' }}>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Created At</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Template</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Number of Message IDs</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {grouped.map((group) => (
              <tr key={`${group.createdAt}__${group.template}`} style={{ borderBottom: '1px solid #ddd' }}>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                  {formatTimestamp(group.createdAt)}
                </td>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{group.template}</td>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{group.messageIds.length}</td>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                  <button
                    onClick={() => handleDownloadExcel(group)}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                  >
                    Download Excel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MessageHistory;