import { takeLatest, call, put } from 'redux-saga/effects';
import { createEventApi, getAllEvents, getOneEvent } from '../../utils/Api';
import { CREATE_EVENTS_REQUEST, GET_EVENT_REQUEST, GET_EVENTS_REQUEST } from './actionTypes';
import { createEventListFailure, createEventListSuccess, getEventListFailure, getEventListSuccess } from './actions';


export function* getEventsList(action) {
  const { eventId } = action.payload;
  try {
    const data = yield call(getAllEvents, eventId);
    yield put(getEventListSuccess(data));
  } catch (error) {
    yield put(getEventListFailure(error.message));
  }
}

export function* getEvent(action) {
  const { eventId } = action.payload;
  try {
    const data = yield call(getOneEvent, eventId);
    yield put(getEventListSuccess(data));
  } catch (error) {
    yield put(getEventListFailure(error.message));
  }
}

function* createEventSaga(action) {
  try {
    const response = yield call(createEventApi, action.payload);
    yield put(createEventListSuccess(response.data));
  } catch (error) {
    yield put(createEventListFailure(error.message));
  }
}

export function* eventsSaga() {
  yield takeLatest(GET_EVENTS_REQUEST, getEventsList);
  yield takeLatest(GET_EVENT_REQUEST, getEvent);
  yield takeLatest(CREATE_EVENTS_REQUEST, createEventSaga);
}
