// store/auth/actionTypes.js
export const UPLOAD_TEAMS_REQUEST = 'UPLOAD_TEAMS_REQUEST';
export const UPLOAD_TEAMS_SUCCESS = 'UPLOAD_TEAMS_SUCCESS';
export const UPLOAD_TEAMS_FAILURE = 'UPLOAD_TEAMS_FAILURE';
export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';
export const CREATE_TEAMS_REQUEST = 'CREATE_TEAMS_REQUEST';
export const CREATE_TEAMS_SUCCESS = 'CREATE_TEAMS_SUCCESS';
export const CREATE_TEAMS_FAILURE = 'CREATE_TEAMS_FAILURE';
