import {
  GET_EVENTS_FAILURE, GET_EVENTS_SUCCESS,
  GET_EVENTS_REQUEST, CREATE_EVENTS_FAILURE, CREATE_EVENTS_SUCCESS, CREATE_EVENTS_REQUEST
} from './actionTypes';

export const createEventList = (EventList) => ({
  type: CREATE_EVENTS_REQUEST,
  payload: { EventList },
});

export const createEventListSuccess = (data) => ({
  type: CREATE_EVENTS_SUCCESS,
  payload: data,
});

export const createEventListFailure = (error) => ({
  type: CREATE_EVENTS_FAILURE,
  payload: error,
});

export const getEventList = (eventId) => ({
  type: GET_EVENTS_REQUEST,
  payload: { eventId },
});

export const getEventListSuccess = (data) => ({
  type: GET_EVENTS_SUCCESS,
  payload: data,
});

export const getEventListFailure = (error) => ({
  type: GET_EVENTS_FAILURE,
  payload: error,
});

export const getEvent = (eventId) => ({
  type: GET_EVENTS_REQUEST,
  payload: { eventId },
});

export const getEventSuccess = (data) => ({
  type: GET_EVENTS_SUCCESS,
  payload: data,
});

export const getEventFailure = (error) => ({
  type: GET_EVENTS_FAILURE,
  payload: error,
});
