// import React, { useState, useRef, useEffect } from 'react';
// import Button from '../components/Button';
// import Input from '../components/Input';
// import { useDispatch } from 'react-redux';
// import { loginRequest } from "../store/auth/login/actions";
// import { signupRequest } from "../store/auth/register/actions";
// import { useNavigate } from 'react-router-dom';

// const PinInput = ({ value, onChange, label }) => {
//   const inputRefs = useRef([]);

//   const handleInputChange = (index, event) => {
//     const inputValue = event.target.value;
    
//     if (inputValue.length <= 1) {
//       const newValue = [...value];
//       newValue[index] = inputValue;
//       onChange(newValue);
      
//       if (inputValue && index < 3 && inputRefs.current[index + 1]) {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, event) => {
//     if (event.key === 'Backspace') {
//       if (!value[index] && index > 0) {
//         inputRefs.current[index - 1].focus();
//       } else {
//         const newValue = [...value];
//         newValue[index] = '';
//         onChange(newValue);
//       }
//       event.preventDefault();
//     }
//   };

//   return (
//     <div className="space-y-2">
//       <label>{label}</label>
//       <div className="flex space-x-2">
//         {[0, 1, 2, 3].map((index) => (
//           <Input
//             key={index}
//             ref={(el) => (inputRefs.current[index] = el)}
//             type="text"
//             inputMode="numeric"
//             pattern="[0-9]*"
//             maxLength={1}
//             value={value[index]}
//             onChange={(e) => handleInputChange(index, e)}
//             onKeyDown={(e) => handleKeyDown(index, e)}
//             className="w-12 h-12 text-center border border-gray-300 rounded"
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// const AuthForm = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
  
//   // Define view states as constants
//   const VIEWS = {
//     LOGIN: 'login',
//     SIGNUP: 'signup'
//   };
  
//   const [view, setView] = useState(VIEWS.LOGIN);
//   const [phoneOrEmail, setPhoneOrEmail] = useState("");
//   const [pin, setPin] = useState(["", "", "", ""]);
//   const [userName, setUserName] = useState("");
//   const [isPhoneSignUp, setIsPhoneSignUp] = useState(true);

//   useEffect(() => {
//     if(localStorage.getItem('authToken')){
//       navigate('/homepage');
//     }
//   }, [navigate]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if(!phoneOrEmail){
//       return;
//     }

//     try {
//       if(view === VIEWS.LOGIN){
//         await dispatch(loginRequest(phoneOrEmail, pin.join("")));
//         navigate('/homepage');
//       } else if(view === VIEWS.SIGNUP){
//         await dispatch(signupRequest(userName, phoneOrEmail, pin.join("")));
//         navigate('/login');
//       }
//     } catch (error) {
//       console.error('Authentication error:', error);
//     }
//   };

//   const renderAuthButtons = () => (
//     <div className="flex space-x-2 mb-6">
//       <Button
//         onClick={() => setView(VIEWS.SIGNUP)}
//         className={`flex-1 ${view === VIEWS.SIGNUP ? 'bg-gray-400 text-white' : 'bg-gray-200 text-black'} border border-gray-300`}
//       >
//         Sign Up
//       </Button>
//       <Button
//         onClick={() => setView(VIEWS.LOGIN)}
//         className={`flex-1 ${view === VIEWS.LOGIN ? 'bg-gray-400 text-white' : 'bg-gray-200 text-black'} border border-gray-300`}
//       >
//         Log In
//       </Button>
//     </div>
//   );

//   const renderLoginView = () => (
//     <>
//       {renderAuthButtons()}
//       <form onSubmit={handleSubmit} className="space-y-4">
//         <Input
//           type="text"
//           placeholder="Enter Phone Number/Email ID"
//           value={phoneOrEmail}
//           onChange={(e) => setPhoneOrEmail(e.target.value)}
//         />
//         <PinInput value={pin} onChange={setPin} label="Enter Pin" />
//         <button type="submit" className="w-full mt-4 border-gray-300 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none">
//           Log In
//         </button>
//       </form>
//     </>
//   );

//   const renderSignupView = () => (
//     <>
//       {renderAuthButtons()}
//       <form onSubmit={handleSubmit} className="space-y-4">
//         <div className="space-y-4 mb-4">
//           <label className="flex items-center">
//             <input
//               type="radio"
//               checked={isPhoneSignUp}
//               onChange={() => setIsPhoneSignUp(true)}
//               className="mr-2"
//             />
//             By Phone Number
//           </label>
//           <label className="flex items-center">
//             <input
//               type="radio"
//               checked={!isPhoneSignUp}
//               onChange={() => setIsPhoneSignUp(false)}
//               className="mr-2"
//             />
//             By Email ID
//           </label>
//         </div>
//         <Input
//           type="text"
//           placeholder="Enter Name"
//           value={userName}
//           onChange={(e) => setUserName(e.target.value)}
//         />
//         <Input
//           type={isPhoneSignUp ? "tel" : "email"}
//           placeholder={`Enter ${isPhoneSignUp ? "Phone Number" : "Email ID"}`}
//           value={phoneOrEmail}
//           onChange={(e) => {
//             const value = e.target.value;
//             if (isPhoneSignUp) {
//               if (/^\d*$/.test(value) && value.length <= 10) {
//                 setPhoneOrEmail(value);
//               }
//             } else {
//               setPhoneOrEmail(value);
//             }
//           }}
//         />
//         <PinInput value={pin} onChange={setPin} label="Create Pin" />
//         <button type="submit" className="w-full mt-4 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none">
//           Sign Up
//         </button>
//       </form>
//     </>
//   );

//   return (
//     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
//       <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
//         {view === VIEWS.LOGIN && renderLoginView()}
//         {view === VIEWS.SIGNUP && renderSignupView()}
//       </div>
//     </div>
//   );
// };

// export default AuthForm;
import React, { useState } from 'react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const AuthForm = () => {
  const [view, setView] = useState('login');

  const showLogin = () => setView('login');
  const showSignup = () => setView('signup');

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-l shadow-lg w-full max-w-md border border-gray-100">
        <div className="flex space-x-2 mb-8 bg-gray-100 p-1 rounded-lg">
          <button
            onClick={showLogin}
            className={`flex-1 text-sm font-medium px-4 py-3 rounded-md transition-all duration-200 ${
              view === 'login'
                ? 'bg-white text-blue-600 shadow-sm'
                : 'text-gray-600 hover:text-gray-800'
            }`}
          >
            Log In
          </button>
          <button
            onClick={showSignup}
            className={`flex-1 text-sm font-medium px-4 py-3 rounded-md transition-all duration-200 ${
              view === 'signup'
                ? 'bg-white text-blue-600 shadow-sm'
                : 'text-gray-600 hover:text-gray-800'
            }`}
          >
            Sign Up
          </button>
        </div>

        <div className="transition-all duration-200">
          {view === 'login' && <LoginForm/>}
          {view === 'signup' && <SignupForm />}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;