const Bulletins = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">Bulletins</h2>
      <div className="space-y-4">
        <div className="bg-blue-100 p-4 rounded-lg">
          <p className="font-semibold">Rita:</p>
          <p>Details of entire event decoration is here: sheets.google.com/xxxxx</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg">
          <p className="font-semibold">Mita:</p>
          <p>Baraat ceremony has started. Send all guests to gate 1!</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg">
          <p className="font-semibold">Mita:</p>
          <p>Bride's father has requested a red ribbon.</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg">
          <p className="font-semibold">Rita:</p>
          <p>Update: All welcome tags are given to @Mita for distribution.</p>
        </div>
      </div>
      {/* Input Area */}
      <div className="mt-4">
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
          To: Everyone
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="text"
            name="message"
            id="message"
            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Type your message here..."
          />
          <button className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Send
          </button>
        </div>
      </div>
    </div>
  );

export default Bulletins