// store/auth/reducers.js

import {
  GET_TEMPLATES_SUCCESS, GET_TEMPLATES_FAILURE, CREATE_TEMPLATES_REQUEST,
  CREATE_TEMPLATES_SUCCESS, CREATE_TEMPLATES_FAILURE, GET_TEMPLATES_REQUEST
} from './actionTypes';

const initialState = {
  loading: false,
  error: null,
  templateList: [],
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };
    case GET_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        teamList: action.payload.teamData,
      };
    case CREATE_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default templatesReducer;
