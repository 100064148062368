import React, { useEffect, useState } from 'react';
import { Button } from "../components/ui/Button";
import { Users, Plus } from "lucide-react";
import Template from './Template';
import EventUpdate from './EventUpdate';
import { useDispatch, useSelector } from 'react-redux';
import ReusableTable from '../components/ReusableTable';
import { getTemplateList } from '../store/templates/actions';
import { useParams } from 'react-router-dom';
import MessageHistory from './MessageHistory';

export default function DraftSection() {
  const { templates, templatesLoading } = useSelector((state) => ({
    templates: state.templatesReducer.templateList,
    templatesLoading: state.templatesReducer.loading,
  }));
  const [showTemplate, setShowTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(true);
  const [showEventUpdate, setShowEventUpdate] = useState(false);

  // NEW state for MessageHistory visibility
  const [showMessageHistory, setShowMessageHistory] = useState(false);

  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { eventId } = useParams();

  useEffect(() => {
    dispatch(getTemplateList(eventId));
  }, [dispatch, eventId]);

  const handleSelect = (template) => {
    setSelectedTemplate(template);
    setShowTemplate(false);
    setShowEventUpdate(true);
  };
  if (showMessageHistory) {
    return (
      <div className="container mx-auto p-4">
        <Button variant="outline" onClick={() => setShowMessageHistory(false)}>
          Back
        </Button>
        <MessageHistory />
        {/* 
          Or if your <MessageHistory /> needs the eventId to fetch data:
          <MessageHistory eventId={eventId} />
        */}
      </div>
    );
  }

  if (showTemplate) {
    return <Template onSwitch={() => setShowTemplate(false)} />;
  }

  // If user selected a template, show <EventUpdate />
  if (showEventUpdate) {
    return (
      <div className="p-4">
        <EventUpdate templateData={selectedTemplate} setShowEventUpdate={setShowEventUpdate} />
      </div>
    );
  }

  // Otherwise, show the normal templates list
  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Type', field: 'type' },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Templates List</h1>
      <Button variant="outline" onClick={() => setShowMessageHistory(true)}>
          Message History
        </Button>
      {templatesLoading ? (
        <p>Loading templates...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : templates.length > 0 ? (
        <ReusableTable
          columns={columns}
          data={templates}
          onActionClick={(template) => handleSelect(template)}
          actionText="Select"
        />
      ) : (
        <p>No templates found.</p>
      )}
    </div>
  );
}
