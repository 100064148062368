// src/components/AdminProtected.jsx
import React, { useState } from 'react';

const AdminProtected = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [password, setPassword] = useState('');

  const CORRECT_PASSWORD = process.env.REACT_APP_PASSWORD;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === CORRECT_PASSWORD) {
      setIsAuthed(true);
    } else {
      alert('Incorrect password. Please try again.');
      setPassword('');
    }
  };

  if (!isAuthed) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-sm p-6 bg-white rounded shadow-md">
          <h2 className="mb-4 text-2xl font-semibold text-center">
            Admin Password Required
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="password"
              placeholder="Enter admin password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="w-full py-2 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default AdminProtected;
