import { takeLatest, call, put } from 'redux-saga/effects';
import { getTemplateListSuccess, getTemplateListFailure } from './actions';
import { GET_TEMPLATES_REQUEST } from './actionTypes';
import { getTemplates } from '../../utils/Api';


export function* getTemplatesList(action) {
  const { eventId } = action.payload;
  try {
    const data = yield call(getTemplates, eventId);
    yield put(getTemplateListSuccess(data));
  } catch (error) {
    yield put(getTemplateListFailure(error.message));
  }
}

export function* templatesSaga() {
  yield takeLatest(GET_TEMPLATES_REQUEST, getTemplatesList);
}
