// import React, { useState, useEffect } from 'react';
// import {
//   MessageSquare,
//   Users,
//   UserPlus,
//   Menu,
//   UserCircle,
//   HelpCircle as HelpCircleIcon,
// } from 'lucide-react';
// import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux'; // <-- For Redux

// import { fetchHelpRequests } from '../store/auth/helpRequest/actions';

// import Guests from './GuestManager';
// import NewChecklist from './NewChecklist';
// import SetUpTeam from '../comunications/SetUpTeam';
// import Teams from './Team';
// import Bulletins from './Bulletins';
// import Help from './Help';
// import Profile from './Profile';

// // Safely retrieve the username from localStorage (falls back to an empty string if null).
// const NAME = localStorage.getItem('username') || '';

// export default function Component() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const { eventName, eventId } = useParams();
//   const navigate = useNavigate();
//   const location = useLocation();

//   const dispatch = useDispatch();
//   const { activeRequests } = useSelector((state) => state.helpRequestsReducer);

//   // Fetch active and done help requests whenever eventId changes
//   useEffect(() => {
//     if (!eventId) return;
//     dispatch(fetchHelpRequests(eventId, 'active'));
//     dispatch(fetchHelpRequests(eventId, 'done'));
//   }, [dispatch, eventId]);

//   const activeHelpCount = activeRequests?.length || 0;

//   const getActiveSection = () => {
//     const path = location.pathname.split('/');
//     return path[path.length - 1] || 'bulletins';
//   };

//   const [activeSection, setActiveSection] = useState(getActiveSection());

//   useEffect(() => {
//     setActiveSection(getActiveSection());
//   }, [location]);

//   // Pull in users from your Redux store
//   const { users } = useSelector((state) => ({
//     users: state.teamsReducer.teamMembers,
//   }));

//   // Filter out the communications team members
//   const communicationTeamMembers = users.filter(
//     (user) => user.team === 'communications team'
//   );

//   const handleSectionChange = (section) => {
//     // If user tries to go to "communications," check permission
//     if (section === 'communications') {
//       // If we have no stored user name, we can assume the user is not logged in or not recognized
//       if (!NAME) {
//         alert(
//           'You do not have permission to access the Communications section. (No username found.)'
//         );
//         return;
//       }

//       // Safely compare, ensuring we handle possible null/undefined names
//       const hasPermission = communicationTeamMembers.some((member) =>
//         (member?.name || '').toLowerCase() === NAME.toLowerCase()
//       );

//       if (hasPermission) {
//         setActiveSection(section);
//         navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
//       } else {
//         alert(
//           'You do not have permission to access the Communications section.'
//         );
//         return;
//       }
//     } else {
//       // For all other sections, just navigate
//       setActiveSection(section);
//       navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
//     }
//   };

//   const renderSection = () => {
//     switch (activeSection) {
//       case 'bulletins':
//         return <Bulletins />;
//       case 'guests':
//         return <Guests />;
//       case 'newchecklist':
//         return <NewChecklist />;
//       case 'teams':
//         return <Teams />;
//       case 'communications':
//         return <SetUpTeam />;
//       case 'help':
//         return <Help />;
//       case 'profile':
//         return <Profile />;
//       default:
//         return <Bulletins />;
//     }
//   };

//   return (
//     <div className="flex h-screen bg-gray-100">
//       {/* Mobile toggle button for sidebar */}
//       <button
//         className="md:hidden p-2 fixed top-4 left-4 z-20 text-gray-600 bg-transparent hover:text-black focus:outline-none"
//         onClick={() => setSidebarOpen(!sidebarOpen)}
//       >
//         <Menu className="w-6 h-6" />
//         {activeHelpCount > 0 && (
//           <span
//             className="
//               absolute
//               top-1
//               right-1
//               bg-red-600
//               text-white
//               text-xs
//               font-bold
//               rounded-full
//               px-1
//               py-0
//               transform
//               translate-x-1/2
//               -translate-y-1/2
//             "
//             style={{ minWidth: '1rem', height: '1rem' }}
//           >
//             {activeHelpCount}
//           </span>
//         )}
//       </button>

//       {/* Sidebar */}
//       <div
//         className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white p-4 transform ${
//           sidebarOpen ? 'translate-x-0' : '-translate-x-full'
//         } md:relative md:translate-x-0 transition-transform duration-300 ease-in-out z-30`}
//       >
//         <h1
//           className="text-2xl font-bold mb-8 cursor-pointer"
//           onClick={() => navigate('/homepage')}
//         >
//           Event: {eventName}
//         </h1>

//         <nav className="space-y-2">
//           <button
//             className={`w-full text-left py-2 px-4 rounded ${
//               activeSection === 'guests'
//                 ? 'bg-blue-600'
//                 : 'hover:bg-gray-700'
//             }`}
//             onClick={() => handleSectionChange('guests')}
//           >
//             <Users className="inline-block mr-2" size={18} /> Guests
//           </button>

//           <button
//             className={`w-full text-left py-2 px-4 rounded ${
//               activeSection === 'teams'
//                 ? 'bg-blue-600'
//                 : 'hover:bg-gray-700'
//             }`}
//             onClick={() => handleSectionChange('teams')}
//           >
//             <UserPlus className="inline-block mr-2" size={18} /> Teams
//           </button>

//           <button
//             className={`w-full text-left py-2 px-4 rounded ${
//               activeSection === 'communications'
//                 ? 'bg-blue-600'
//                 : 'hover:bg-gray-700'
//             }`}
//             onClick={() => handleSectionChange('communications')}
//           >
//             <MessageSquare className="inline-block mr-2" size={18} /> Communications
//           </button>

//           <button
//             className={`w-full text-left py-2 px-4 rounded ${
//               activeSection === 'help'
//                 ? 'bg-blue-600'
//                 : 'hover:bg-gray-700'
//             }`}
//             onClick={() => handleSectionChange('help')}
//           >
//             <HelpCircleIcon className="inline-block mr-2" size={18} />
//             Help ({activeHelpCount})
//           </button>

//           <button
//             className={`w-full text-left py-2 px-4 rounded ${
//               activeSection === 'profile'
//                 ? 'bg-blue-600'
//                 : 'hover:bg-gray-700'
//             }`}
//             onClick={() => handleSectionChange('profile')}
//           >
//             <UserCircle className="inline-block mr-2" size={18} />
//             Profile
//           </button>
//         </nav>
//       </div>

//       {/* Overlay when sidebar is open (mobile) */}
//       {sidebarOpen && (
//         <div
//           className="fixed inset-0 bg-black opacity-50 z-20"
//           onClick={() => setSidebarOpen(false)}
//         />
//       )}

//       {/* Main Content */}
//       <div className="flex-1 p-8 overflow-auto">
//         <h2 className="text-3xl font-bold m-3">{eventName}</h2>
//         {renderSection()}
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from 'react';
import {
  MessageSquare,
  Users,
  UserPlus,
  Menu,
  UserCircle,
  HelpCircle as HelpCircleIcon,
} from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchHelpRequests } from '../store/auth/helpRequest/actions';

import Guests from './GuestManager';
import NewChecklist from './NewChecklist';
import SetUpTeam from '../comunications/SetUpTeam';
import Teams from './Team';
import Bulletins from './Bulletins';
import Help from './Help';
import Profile from './Profile';

// Safely retrieve the phone number from localStorage (falls back to an empty string if null)
const PHONE_NUMBER = localStorage.getItem('phone_number') || '';

export default function Component() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { eventName, eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { activeRequests } = useSelector((state) => state.helpRequestsReducer);

  // Fetch active and done help requests whenever eventId changes
  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchHelpRequests(eventId, 'active'));
    dispatch(fetchHelpRequests(eventId, 'done'));
  }, [dispatch, eventId]);

  const activeHelpCount = activeRequests?.length || 0;

  const getActiveSection = () => {
    const path = location.pathname.split('/');
    return path[path.length - 1] || 'bulletins';
  };

  const [activeSection, setActiveSection] = useState(getActiveSection());

  useEffect(() => {
    setActiveSection(getActiveSection());
  }, [location]);

  // Pull in users from your Redux store
  const { users } = useSelector((state) => ({
    users: state.teamsReducer.teamMembers,
  }));

  // Filter out the communications team members
  const communicationTeamMembers = users.filter(
    (user) => user.team === 'communications team'
  );

  const handleSectionChange = (section) => {
    // If user tries to go to "communications," check permission
    if (section === 'communications') {
      // If we have no stored phone number, we can assume the user is not logged in or not recognized
      if (!PHONE_NUMBER) {
        alert(
          'You do not have permission to access the Communications section. (No phone number found.)'
        );
        return;
      }

      // Safely compare phone numbers
      const hasPermission = communicationTeamMembers.some((member) =>
        (member?.phone || '') === PHONE_NUMBER
      );

      if (hasPermission) {
        setActiveSection(section);
        navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
      } else {
        alert(
          'You do not have permission to access the Communications section.'
        );
        return;
      }
    } else {
      // For all other sections, just navigate
      setActiveSection(section);
      navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'bulletins':
        return <Bulletins />;
      case 'guests':
        return <Guests />;
      case 'newchecklist':
        return <NewChecklist />;
      case 'teams':
        return <Teams />;
      case 'communications':
        return <SetUpTeam />;
      case 'help':
        return <Help />;
      case 'profile':
        return <Profile />;
      default:
        return <Bulletins />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Mobile toggle button for sidebar */}
      <button
        className="md:hidden p-2 fixed top-4 left-4 z-20 text-gray-600 bg-transparent hover:text-black focus:outline-none"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <Menu className="w-6 h-6" />
        {activeHelpCount > 0 && (
          <span
            className="
              absolute
              top-1
              right-1
              bg-red-600
              text-white
              text-xs
              font-bold
              rounded-full
              px-1
              py-0
              transform
              translate-x-1/2
              -translate-y-1/2
            "
            style={{ minWidth: '1rem', height: '1rem' }}
          >
            {activeHelpCount}
          </span>
        )}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white p-4 transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:relative md:translate-x-0 transition-transform duration-300 ease-in-out z-30`}
      >
        <h1
          className="text-2xl font-bold mb-8 cursor-pointer"
          onClick={() => navigate('/homepage')}
        >
          Event: {eventName}
        </h1>

        <nav className="space-y-2">
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'guests'
                ? 'bg-blue-600'
                : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('guests')}
          >
            <Users className="inline-block mr-2" size={18} /> Guests
          </button>

          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'teams'
                ? 'bg-blue-600'
                : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('teams')}
          >
            <UserPlus className="inline-block mr-2" size={18} /> Teams
          </button>

          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'communications'
                ? 'bg-blue-600'
                : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('communications')}
          >
            <MessageSquare className="inline-block mr-2" size={18} /> Communications
          </button>

          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'help'
                ? 'bg-blue-600'
                : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('help')}
          >
            <HelpCircleIcon className="inline-block mr-2" size={18} />
            Help ({activeHelpCount})
          </button>

          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'profile'
                ? 'bg-blue-600'
                : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('profile')}
          >
            <UserCircle className="inline-block mr-2" size={18} />
            Profile
          </button>
        </nav>
      </div>

      {/* Overlay when sidebar is open (mobile) */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-20"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      {/* Main Content */}
      <div className="flex-1 p-8 overflow-auto">
        <h2 className="text-3xl font-bold m-3">{eventName}</h2>
        {renderSection()}
      </div>
    </div>
  );
}