// store/auth/reducers.js

import {
  UPLOAD_TEAMS_FAILURE, UPLOAD_TEAMS_SUCCESS, UPLOAD_TEAMS_REQUEST, GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE, CREATE_TEAMS_REQUEST, CREATE_TEAMS_SUCCESS, CREATE_TEAMS_FAILURE,
  GET_TEAMS_REQUEST
} from './actionTypes';

const initialState = {
  loading: false,
  error: null,
  teamList: [],
  teamMembers: []
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamList: action.payload.teamData,
      };
    case UPLOAD_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamMembers: action.payload.teamData,
      };
    case GET_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamList: action.payload.teamData,
      };
    case CREATE_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default teamsReducer;
