import { FileText } from "lucide-react";
import { Button } from "./ui/Button";
import { useRef, useState, useEffect } from "react";
import { Upload, UserPlus } from 'lucide-react';
import AddTeamModal from "./AddTeamModal";
import { useDispatch } from "react-redux"; 
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { getTeamList, uploadTeamList } from '../store/teams/actions';
import { useLocation, useParams } from "react-router-dom";
import { getGuestList } from "../store/guests/actions";
import { getEvent } from "../store/events/actions";


const Teams = () => {
  const { teamList, teamListLoading, currentEvent, events } = useSelector((state) => ({
    teamList: state.teamsReducer.teamMembers,
    teamListLoading: state.teamsReducer.loading,
    currentEvent: state.eventsReducer.currentEvent,
    events: state.eventsReducer.eventsList
  }));
  const location = useLocation();
  const { event } = location.state || {};
  const [teams, setTeams] = useState([]); 
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [newTeam, setNewTeam] = useState({ name: "" }); 
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { eventId } = useParams();
  // const [teamList, setTeamList] = useState([]);

  useEffect(()=>{
    // const event = events.find(event => event.id === parseInt(eventId));
    // setTeamList(event.Teams)
    dispatch(getTeamList(eventId))
  },[dispatch])

  // useEffect(()=>{
  //   const selectId = currentEvent?.id || event?.id
  //   dispatch(getTeamList(selectId,[]));
  //   if(currentEvent?.length == 0){
  //     dispatch(getEvent(selectId,[]));
  //   }
  // },[dispatch])

  const handleAddTeam = (e) => {
    e.preventDefault();
    if (newTeam.name.trim()) { 
      // dispatch(createTeam(newTeam.name)); 
      setTeams([...teams, { id: teams.length + 1, ...newTeam }]); 
      setNewTeam({ name: "" });
      setIsAddTeamModalOpen(false);
    }
  };

  useEffect(()=>{
    if(teamList?.length > 0){
      const keys = Object.keys(teamList[0]);
      const team_columns = keys.map(key => ({
        title: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
        field: key
      }));
      setColumns(team_columns)
      setTeams(teamList)
    }
  },[teamList])

  const handleUploadExcel = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryString = evt.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      if (!data || data.length === 0) return;
      
      const headers = data[0];
      setColumns(headers);
      
      const rows = data
      .slice(1) // Skip the header row
      .filter((row) =>
        row.some((cell) => cell && String(cell).trim() !== "") // Remove rows where all cells are empty or whitespace
      )
      .map((row, index) => {
        const Team = { id: index + 1 };
        headers.forEach((header, i) => {
          Team[header] = row[i] ? String(row[i]).trim() : ''; // Trim spaces from cell values
        });
        return Team;
      });

      dispatch(uploadTeamList(eventId, rows))
      setTeams(rows);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Teams</h2>
      <div className="flex justify-end gap-2 mb-4">
        <Button variant="outline" size="sm" className="flex items-center gap-2">
          <FileText className="h-4 w-4" />
          Template
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="flex items-center gap-2"
          onClick={() => setIsAddTeamModalOpen(true)}
        >
          Add Team
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadExcel}
          accept=".xlsx, .xls"
          className="hidden"
        />
        {/* Upload Excel Button */}
        <button
          onClick={() => fileInputRef.current.click()}
          className="flex items-center px-3 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors text-xs"
        >
          <Upload size={16} className="mr-2" />
          Upload Teams Data
        </button>
      </div>
      <AddTeamModal
        isOpen={isAddTeamModalOpen}
        onClose={() => setIsAddTeamModalOpen(false)}
        title="Add New Team"
      >
        <form onSubmit={handleAddTeam}>
          <input
            type="text"
            placeholder="Enter Team Name"
            value={newTeam.name || ""}
            onChange={(e) =>
              setNewTeam({ ...newTeam, name: e.target.value })
            }
            className="w-full p-1 mb-2 text-ms border rounded"
          />
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setIsAddTeamModalOpen(false)} 
              className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors text-ms"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-3 py-1 bg-black text-white rounded-md hover:bg-gray-800 transition-colors text-ms"
            >
              Add Team
            </button>
          </div>
        </form>
      </AddTeamModal>

      {/* teams List Table */}
      {teams.length > 0 && (
        <div className="overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-xs text-left"> {/* Full-width table */}
          <thead className="text-xxs uppercase bg-gray-100">
            <tr>
              {columns.map((column, index) => (
                <th key={index} className="px-1 py-1">{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {teams.map((team) => (
              <tr key={team.id} className="bg-white even:bg-gray-50">
                {columns.map((column, colIndex) => (
                  <td key={colIndex} className="px-1 py-1">{team[column.field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Teams;
