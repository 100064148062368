import React, { useState } from 'react';
import { Button } from "../components/ui/Button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/TableComponents";
import { FileText, Users, Plus } from "lucide-react";
import EventUpdate from "./EventUpdate";

export default function Template({ onSwitch }) {
  const [showEventUpdate, setShowEventUpdate] = useState(false); 
  const templates = [
    { no: 1, templateName: "Draft1", category: "RSVP", type: "Text" },
    { no: 2, templateName: "Lunch-2", category: "Itinerary", type: "Document" },
  ];

  const handleSelect = () => {
    setShowEventUpdate(true); 
  };

  return (
    <div className="container mx-auto p-4">
      {showEventUpdate ? (
        <div className="p-4">
          <EventUpdate />
        </div>
      ) : (
        <div>
          <div className="flex justify-end gap-2 mb-4">
            <Button
              variant="outline"
              size="sm"
              className="flex items-center gap-2"
              onClick={onSwitch}
            >
              <FileText className="h-4 w-4" />
              Draft
            </Button>
            <Button variant="outline" size="sm" className="flex items-center gap-2">
              <Users className="h-4 w-4" />
              Team
            </Button>
          </div>

          <div className="overflow-x-auto">
          {/* <ReusableTable
            columns={columns}
            data={templates}
            onActionClick={handleSelect}
            actionText="Select" */}
          {/* /> */}
          </div>

          <div className="mt-4 flex justify-start">
            <Button variant="outline" size="icon">
              <Plus className="h-4 w-4" />
              <span className="sr-only">Add</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
