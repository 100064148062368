// store/auth/reducers.js

import {
  GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE, CREATE_EVENTS_REQUEST,
  CREATE_EVENTS_SUCCESS, CREATE_EVENTS_FAILURE, GET_EVENTS_REQUEST,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE
} from './actionTypes';

const initialState = {
  loading: false,
  error: null,
  eventsList: [],
  currentEvent: []
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsList: action.payload,
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEvent: action.payload,
      };
    case GET_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsList: action.payload.teamData,
      };
    case CREATE_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default eventsReducer;
