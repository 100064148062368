import React,{useRef} from 'react'
import Input from '../components/Input'

const PinInput = ({value,onChange,label}) => {
    const inputRefs = useRef([]);
    const handleInputChange = (index,event)=>{
        const inputValue = event.target.value;
        if(inputValue.length<=1){
            const newValue = [...value];
            newValue[index]= inputValue;
            onChange(newValue);

            if(inputValue && index < 3 && inputRefs.current[index+1]){
                inputRefs.current[index+1].focus();
            }
        }
    };
    const handleKeyDown = (index,event)=>{
        if(event.key==='Backspace'){
         if(!value[index] && index>0){
            inputRefs.current[index-1].focus();
         }
         else{
            const newValue = [...value];
            newValue[index]='';
            onChange(newValue);
         }
         event.preventDefault();
        }
    }
    return (
        <div className="space-y-2">
          <label>{label}</label>
          <div className="flex space-x-2">
            {[0, 1, 2, 3].map((i) => (
              <Input
                key={i}
                ref={(el) => (inputRefs.current[i] = el)}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                value={value[i]}
                onChange={(e) => handleInputChange(i, e)}
                onKeyDown={(e) => handleKeyDown(i, e)}
                className="w-12 h-12 text-center border border-gray-300 rounded"
              />
            ))}
          </div>
        </div>
      );
    };
    


export default PinInput;
