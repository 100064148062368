import config from "../config";
// const BACK_END_URL = 'http://localhost:3002'

const login = async (email, password) => {
  const isEmail = inputIsEmail(email)
  try {
    const response = await fetch(`${config.BACKEND_URL}/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email: isEmail ? email : '',
        phone_number: !isEmail ? email : '',
        password: password
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const inputIsEmail = (input) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(input)) {
    return true;
  }
  return false
};


const signup = async (userName, email, password, phoneNumber) => {
  const isEmail = inputIsEmail(email)
  try {
    const response = await fetch(`${config.BACKEND_URL}/users/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        name: userName,
        phone_number: !isEmail ? email : '',
        email: email,
        password: password
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const uploadGuests = async (eventId, guestList) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/upload-guest-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify({
        eventId: eventId,
        guestList: guestList
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getGuestsList = async (eventId, filters) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/guest-list/${eventId}?category=${filters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getTemplates = async (eventId) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/templates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const uploadteams = async (eventId, teamList) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/create-team`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify({
        eventId: eventId,
        teamData: teamList
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getTeam = async (eventId) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/team-data/${eventId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


const createTeam = async (eventId, teamData) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/create-team`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify({
        eventId: eventId,
        teamData: teamData
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getAllEvents = async () => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getOneEvent = async (eventId) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/${eventId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


const createEventApi = async (event) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('authToken'),
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        name: event.EventList.name,
        description: event.EventList.description,
        end_date: event.EventList.end_date,
        start_date: event.EventList.start_date,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export { login, signup, uploadGuests, uploadteams, getTeam, createTeam, getGuestsList, getTemplates, getAllEvents, createEventApi, getOneEvent }
