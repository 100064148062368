import React, { useState , useEffect } from 'react';
import { MessageSquare, Users, CheckSquare, UserPlus, Menu, Link } from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Guests from './GuestManager';
import NewChecklist from './NewChecklist';
import SetUpTeam from '../comunications/SetUpTeam';
import Teams from './Team';
import Bulletins from './Bulletins';

export default function Component() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { eventName } = useParams();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveSection = () => {
    const path = location.pathname.split('/');
    return path[path.length - 1] || 'bulletins';
  };

  const [activeSection, setActiveSection] = useState(getActiveSection());

  useEffect(() => {
    setActiveSection(getActiveSection());
  }, [location]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'bulletins':
        return <Bulletins />;
      case 'guests':
        return <Guests />;
      case 'newchecklist':
        return <NewChecklist />;
      case 'teams':
        return <Teams />;
      case 'communications':
        return <SetUpTeam />;
      default:
        return <Bulletins />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Hamburger Menu Button (Mobile View) */}
      <button
        className="md:hidden p-2 fixed top-4 left-4 z-20 text-gray-600 bg-transparent hover:text-black focus:outline-none"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <Menu className="w-6 h-6" />
      </button>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white p-4 transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:relative md:translate-x-0 transition-transform duration-300 ease-in-out z-30`}
      >
        <h1
          className="text-2xl font-bold mb-8 cursor-pointer"
          onClick={() => navigate("/homepage")}
        >
          Event: {eventName}
        </h1>
        <nav className="space-y-2">
          {/* <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'bulletins' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('bulletins')}
          >
            <MessageSquare className="inline-block mr-2" size={18} /> Bulletins
          </button> */}
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'guests' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('guests')}
          >
            <Users className="inline-block mr-2" size={18} /> Guests
          </button>
          {/* <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'newchecklist' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('newchecklist')}
          >
            <CheckSquare className="inline-block mr-2" size={18} /> Checklists
          </button> */}
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'teams' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('teams')}
          >
            <UserPlus className="inline-block mr-2" size={18} /> Teams
          </button>
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'communications' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('communications')}
          >
            <MessageSquare className="inline-block mr-2" size={18} /> Communications
          </button>
        </nav>
      </div>

      {/* Overlay when sidebar is open (for mobile) */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}

      {/* Main Content */}
      <div className="flex-1 p-8 overflow-auto">
        {/* <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
          <p>Your workspace has been created successfully!</p>
        </div> */}
        <h2 className="text-3xl font-bold m-3">{eventName}</h2>
        {renderSection()}
      </div>
    </div>
  );
}